import validate from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/middleware/admin.ts"),
  auth: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/middleware/auth.ts"),
  "member-access": () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/middleware/memberAccess.ts"),
  redirect: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/middleware/redirect.ts")
}