import { default as account_45lookupRCFyDXhR7NMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/account-lookup.vue?macro=true";
import { default as add6d0hcVry9EMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/add.vue?macro=true";
import { default as deletedylPXDDbC2FMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/deleted.vue?macro=true";
import { default as _91id_93QBafQtEGRqMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/edit/[id].vue?macro=true";
import { default as index9A3NJvx7hCMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/index.vue?macro=true";
import { default as _91id_93cXYrVLnWyIMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/view/[id].vue?macro=true";
import { default as companies7Fh7jfD9y0Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies.vue?macro=true";
import { default as _91id_93m1g6KohrS9Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/company-suggestions/confirm/[id].vue?macro=true";
import { default as index29xIxQj7IzMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/company-suggestions/index.vue?macro=true";
import { default as indexrVDsp02oy4Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/configs/index.vue?macro=true";
import { default as dashboardFNVQudLa0VMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/dashboard.vue?macro=true";
import { default as addC8man5qojhMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/add.vue?macro=true";
import { default as _91id_93gsdyhIr2YLMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/appeal/[id].vue?macro=true";
import { default as _91id_937O2T53kZ5sMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/edit/[id].vue?macro=true";
import { default as indexaoRWiMpL92Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/index.vue?macro=true";
import { default as _91id_93BIQRahNxaBMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/view/[id].vue?macro=true";
import { default as index2GcZOctAetMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/judges/index.vue?macro=true";
import { default as indexqaHbIV2EDUMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/card-scan/index.vue?macro=true";
import { default as indexEyVoaQV9uRMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/email/index.vue?macro=true";
import { default as indextfg4AunySaMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/index.vue?macro=true";
import { default as indexCcfAHI65UGMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/mailchimp/index.vue?macro=true";
import { default as index3kIsSAUs4TMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/payment/index.vue?macro=true";
import { default as logsiB6HwT2g6XMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs.vue?macro=true";
import { default as addsVoeTK2M7rMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/add.vue?macro=true";
import { default as indee_45active_45membersnkscnN0LITMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/indee-active-members.vue?macro=true";
import { default as indexzSwUr2sGFqMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/index.vue?macro=true";
import { default as indexhcS7UkDhKZMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/merge/[id]/index.vue?macro=true";
import { default as _91withId_936lGxdOMT5tMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/merge/[id]/with/[withId].vue?macro=true";
import { default as index3f2eruH9uRMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/merge/index.vue?macro=true";
import { default as _91id_93Ob8lobfRIKMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/merge/resolve/[id].vue?macro=true";
import { default as resend_45welcome_45emaildGepZPXsGwMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/resend-welcome-email.vue?macro=true";
import { default as screener_45listsQ0eKcLRHYUMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/screener-lists.vue?macro=true";
import { default as indexIVWdr345zyMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/student-applications/index.vue?macro=true";
import { default as _91id_93eWVzhGBVKTMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/student-applications/view/[id].vue?macro=true";
import { default as membersjs6obQNo4tMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members.vue?macro=true";
import { default as statistics9lDlzLJbHFMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/memberships/statistics.vue?macro=true";
import { default as _91id_93bbOboecGp9Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/memberships/update/[id].vue?macro=true";
import { default as indexIcnw4ItefdMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/payments/index.vue?macro=true";
import { default as addgYpYuMbT02Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code/add.vue?macro=true";
import { default as _91id_93XL4AAKiE2jMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code/edit/[id].vue?macro=true";
import { default as index3q8eXYBrpWMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code/index.vue?macro=true";
import { default as _91id_936luJi2xfEIMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code/view/[id].vue?macro=true";
import { default as promo_45codecoA18I3v3nMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code.vue?macro=true";
import { default as screeningsYe1Q5EuBwqMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/screenings.vue?macro=true";
import { default as indexxRTPOHAizhMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/tickets/index.vue?macro=true";
import { default as invitec7OZA8s0s4Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/tickets/invite.vue?macro=true";
import { default as _91code_93LsytsChfKoMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/tickets/receipt/[code].vue?macro=true";
import { default as ticketslOCFf4ez2JMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/tickets.vue?macro=true";
import { default as indexeAN1Hcjrq0Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/voter-qualifications/index.vue?macro=true";
import { default as dashboard5A6umD8twSMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/dashboard.vue?macro=true";
import { default as indexo0k87GRx0rMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/index.vue?macro=true";
import { default as _91invoiceId_93ySWQymxCawMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/invoice/[invoiceId].vue?macro=true";
import { default as addgNXoI1HvwiMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/add.vue?macro=true";
import { default as multiple_45addiTDKQ8cGKIMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/multiple-add.vue?macro=true";
import { default as indexMpNxjfc2ekMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/resolve-similar/index.vue?macro=true";
import { default as companiesMC4RvGq0eSMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies.vue?macro=true";
import { default as indexAgkjEayHTqMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/events/screening/[id]/index.vue?macro=true";
import { default as forgot_45passwordI1ERYdIM9dMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/forgot-password.vue?macro=true";
import { default as indexDCmOPOyWa0Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/index.vue?macro=true";
import { default as invoiceszoq91EhECdMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/invoices.vue?macro=true";
import { default as applyIWAFWrReyjMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/judges/apply.vue?macro=true";
import { default as _91_46_46_46id_93VNwBjFVE72Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/addresses/[...id].vue?macro=true";
import { default as _91id_93q9agHl8h93Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/edit/[id].vue?macro=true";
import { default as indexlzG5UjfzP1Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/membership/index.vue?macro=true";
import { default as indexJbZOIFOKt3Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/signup/index.vue?macro=true";
import { default as _91_46_46_46id_93VvFmE1bjb2Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/view/[...id].vue?macro=true";
import { default as membersQcKtf5WIXgMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members.vue?macro=true";
import { default as indexz7NAvKntt5Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/card/index.vue?macro=true";
import { default as _91_46_46_46id_93LgwFC64VFYMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/card/print/[...id].vue?macro=true";
import { default as _91id_930ZR1eUoGrzMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/invoice/[id].vue?macro=true";
import { default as indexg8FDLILO2uMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/my-membership/index.vue?macro=true";
import { default as _91id_93mrVImXhxyOMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/update/[id].vue?macro=true";
import { default as _91invoiceId_936c31ZiOGeEMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/update/invoice/[invoiceId].vue?macro=true";
import { default as password_45resetnV1KIbNY4jMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/password-reset.vue?macro=true";
import { default as indexZ7mle6RottMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/payments/index.vue?macro=true";
import { default as _91id_93KhZcLIv9JxMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/payments/invoice/[id].vue?macro=true";
import { default as _91id_93siwjsx4tauMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/payments/receipt/[id].vue?macro=true";
import { default as _91id_93Sy75HaHOaYMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/payments/view/[id].vue?macro=true";
import { default as callbackX8ZzUsmRgTMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/qb/callback.vue?macro=true";
import { default as logout8TSeNY30xQMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/redirect/logout.vue?macro=true";
import { default as indexROvi7RaBJ4Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/index.vue?macro=true";
import { default as _91token_93I7w873Q9D6Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/invite/[token].vue?macro=true";
import { default as _91token_93viS5IBaPctMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/invite/payment/[token].vue?macro=true";
import { default as _91code_936dOl19up38Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/payment/[code].vue?macro=true";
import { default as indexd9NozRKcr2Meta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/payment/index.vue?macro=true";
import { default as _91code_93cxHxuNi7AlMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/receipt/[code].vue?macro=true";
import { default as _91code_93JClcMDW0VTMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/view/[code].vue?macro=true";
import { default as ticketsTiaMJ9cUXYMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets.vue?macro=true";
import { default as votepjAM2gytRJMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/vote.vue?macro=true";
import { default as component_45stubBaRksBa3tIMeta } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubBaRksBa3tI } from "/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account-lookup",
    path: "/account-lookup",
    meta: account_45lookupRCFyDXhR7NMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/account-lookup.vue")
  },
  {
    name: companies7Fh7jfD9y0Meta?.name,
    path: "/admin/companies",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies.vue"),
    children: [
  {
    name: "admin-companies-add",
    path: "add",
    meta: add6d0hcVry9EMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/add.vue")
  },
  {
    name: "admin-companies-deleted",
    path: "deleted",
    meta: deletedylPXDDbC2FMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/deleted.vue")
  },
  {
    name: "admin-companies-edit-id",
    path: "edit/:id()",
    meta: _91id_93QBafQtEGRqMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/edit/[id].vue")
  },
  {
    name: "admin-companies",
    path: "",
    meta: index9A3NJvx7hCMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/index.vue")
  },
  {
    name: "admin-companies-view-id",
    path: "view/:id()",
    meta: _91id_93cXYrVLnWyIMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/view/[id].vue")
  }
]
  },
  {
    name: "admin-company-suggestions-confirm-id",
    path: "/admin/company-suggestions/confirm/:id()",
    meta: _91id_93m1g6KohrS9Meta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/company-suggestions/confirm/[id].vue")
  },
  {
    name: "admin-company-suggestions",
    path: "/admin/company-suggestions",
    meta: index29xIxQj7IzMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/company-suggestions/index.vue")
  },
  {
    name: "admin-configs",
    path: "/admin/configs",
    meta: indexrVDsp02oy4Meta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/configs/index.vue")
  },
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    meta: dashboardFNVQudLa0VMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/dashboard.vue")
  },
  {
    name: "admin-events-add",
    path: "/admin/events/add",
    meta: addC8man5qojhMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/add.vue")
  },
  {
    name: "admin-events-appeal-id",
    path: "/admin/events/appeal/:id()",
    meta: _91id_93gsdyhIr2YLMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/appeal/[id].vue")
  },
  {
    name: "admin-events-edit-id",
    path: "/admin/events/edit/:id()",
    meta: _91id_937O2T53kZ5sMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/edit/[id].vue")
  },
  {
    name: "admin-events",
    path: "/admin/events",
    meta: indexaoRWiMpL92Meta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/index.vue")
  },
  {
    name: "admin-events-view-id",
    path: "/admin/events/view/:id()",
    meta: _91id_93BIQRahNxaBMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/view/[id].vue")
  },
  {
    name: "admin-judges",
    path: "/admin/judges",
    meta: index2GcZOctAetMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/judges/index.vue")
  },
  {
    name: logsiB6HwT2g6XMeta?.name,
    path: "/admin/logs",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs.vue"),
    children: [
  {
    name: "admin-logs-card-scan",
    path: "card-scan",
    meta: indexqaHbIV2EDUMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/card-scan/index.vue")
  },
  {
    name: "admin-logs-email",
    path: "email",
    meta: indexEyVoaQV9uRMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/email/index.vue")
  },
  {
    name: "admin-logs",
    path: "",
    meta: indextfg4AunySaMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/index.vue")
  },
  {
    name: "admin-logs-mailchimp",
    path: "mailchimp",
    meta: indexCcfAHI65UGMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/mailchimp/index.vue")
  },
  {
    name: "admin-logs-payment",
    path: "payment",
    meta: index3kIsSAUs4TMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/payment/index.vue")
  }
]
  },
  {
    name: membersjs6obQNo4tMeta?.name,
    path: "/admin/members",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members.vue"),
    children: [
  {
    name: "admin-members-add",
    path: "add",
    meta: addsVoeTK2M7rMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/add.vue")
  },
  {
    name: "admin-members-indee-active-members",
    path: "indee-active-members",
    meta: indee_45active_45membersnkscnN0LITMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/indee-active-members.vue")
  },
  {
    name: "admin-members",
    path: "",
    meta: indexzSwUr2sGFqMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/index.vue")
  },
  {
    name: "admin-members-merge-id",
    path: "merge/:id()",
    meta: indexhcS7UkDhKZMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/merge/[id]/index.vue")
  },
  {
    name: "admin-members-merge-id-with-withId",
    path: "merge/:id()/with/:withId()",
    meta: _91withId_936lGxdOMT5tMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/merge/[id]/with/[withId].vue")
  },
  {
    name: "admin-members-merge",
    path: "merge",
    meta: index3f2eruH9uRMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/merge/index.vue")
  },
  {
    name: "admin-members-merge-resolve-id",
    path: "merge/resolve/:id()",
    meta: _91id_93Ob8lobfRIKMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/merge/resolve/[id].vue")
  },
  {
    name: "admin-members-resend-welcome-email",
    path: "resend-welcome-email",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/resend-welcome-email.vue")
  },
  {
    name: "admin-members-screener-lists",
    path: "screener-lists",
    meta: screener_45listsQ0eKcLRHYUMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/screener-lists.vue")
  },
  {
    name: "admin-members-student-applications",
    path: "student-applications",
    meta: indexIVWdr345zyMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/student-applications/index.vue")
  },
  {
    name: "admin-members-student-applications-view-id",
    path: "student-applications/view/:id()",
    meta: _91id_93eWVzhGBVKTMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/student-applications/view/[id].vue")
  }
]
  },
  {
    name: "admin-memberships-statistics",
    path: "/admin/memberships/statistics",
    meta: statistics9lDlzLJbHFMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/memberships/statistics.vue")
  },
  {
    name: "admin-memberships-update-id",
    path: "/admin/memberships/update/:id()",
    meta: _91id_93bbOboecGp9Meta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/memberships/update/[id].vue")
  },
  {
    name: "admin-payments",
    path: "/admin/payments",
    meta: indexIcnw4ItefdMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/payments/index.vue")
  },
  {
    name: promo_45codecoA18I3v3nMeta?.name,
    path: "/admin/promo-code",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code.vue"),
    children: [
  {
    name: "admin-promo-code-add",
    path: "add",
    meta: addgYpYuMbT02Meta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code/add.vue")
  },
  {
    name: "admin-promo-code-edit-id",
    path: "edit/:id()",
    meta: _91id_93XL4AAKiE2jMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code/edit/[id].vue")
  },
  {
    name: "admin-promo-code",
    path: "",
    meta: index3q8eXYBrpWMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code/index.vue")
  },
  {
    name: "admin-promo-code-view-id",
    path: "view/:id()",
    meta: _91id_936luJi2xfEIMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code/view/[id].vue")
  }
]
  },
  {
    name: "admin-screenings",
    path: "/admin/screenings",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/screenings.vue")
  },
  {
    name: ticketslOCFf4ez2JMeta?.name,
    path: "/admin/tickets",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/tickets.vue"),
    children: [
  {
    name: "admin-tickets",
    path: "",
    meta: indexxRTPOHAizhMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/tickets/index.vue")
  },
  {
    name: "admin-tickets-invite",
    path: "invite",
    meta: invitec7OZA8s0s4Meta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/tickets/invite.vue")
  },
  {
    name: "admin-tickets-receipt-code",
    path: "receipt/:code()",
    meta: _91code_93LsytsChfKoMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/tickets/receipt/[code].vue")
  }
]
  },
  {
    name: "admin-voter-qualifications",
    path: "/admin/voter-qualifications",
    meta: indexeAN1Hcjrq0Meta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/voter-qualifications/index.vue")
  },
  {
    name: "companies",
    path: "/companies",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies.vue"),
    children: [
  {
    name: "companies-manager-dashboard",
    path: "manager/dashboard",
    meta: dashboard5A6umD8twSMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/dashboard.vue")
  },
  {
    name: "companies-manager-view-id",
    path: "manager/view/:id()",
    meta: indexo0k87GRx0rMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/index.vue")
  },
  {
    name: "companies-manager-view-id-invoice-invoiceId",
    path: "manager/view/:id()/invoice/:invoiceId()",
    meta: _91invoiceId_93ySWQymxCawMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/invoice/[invoiceId].vue")
  },
  {
    name: "companies-manager-view-id-member-add",
    path: "manager/view/:id()/member/add",
    meta: addgNXoI1HvwiMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/add.vue")
  },
  {
    name: "companies-manager-view-id-member-multiple-add",
    path: "manager/view/:id()/member/multiple-add",
    meta: multiple_45addiTDKQ8cGKIMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/multiple-add.vue")
  },
  {
    name: "companies-manager-view-id-member-resolve-similar",
    path: "manager/view/:id()/member/resolve-similar",
    meta: indexMpNxjfc2ekMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/resolve-similar/index.vue")
  }
]
  },
  {
    name: "events-screening-id",
    path: "/events/screening/:id()",
    meta: indexAgkjEayHTqMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/events/screening/[id]/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordI1ERYdIM9dMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexDCmOPOyWa0Meta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/index.vue")
  },
  {
    name: "invoices",
    path: "/invoices",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/invoices.vue")
  },
  {
    name: "judges-apply",
    path: "/judges/apply",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/judges/apply.vue")
  },
  {
    name: "members",
    path: "/members",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members.vue"),
    children: [
  {
    name: "members-addresses-id",
    path: "addresses/:id(.*)*",
    meta: _91_46_46_46id_93VNwBjFVE72Meta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/addresses/[...id].vue")
  },
  {
    name: "members-edit-id",
    path: "edit/:id()",
    meta: _91id_93q9agHl8h93Meta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/edit/[id].vue")
  },
  {
    name: "members-membership",
    path: "membership",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/membership/index.vue")
  },
  {
    name: "members-signup",
    path: "signup",
    meta: indexJbZOIFOKt3Meta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/signup/index.vue")
  },
  {
    name: "members-view-id",
    path: "view/:id(.*)*",
    meta: _91_46_46_46id_93VvFmE1bjb2Meta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/view/[...id].vue")
  }
]
  },
  {
    name: "memberships-card",
    path: "/memberships/card",
    meta: indexz7NAvKntt5Meta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/card/index.vue")
  },
  {
    name: "memberships-card-print-id",
    path: "/memberships/card/print/:id(.*)*",
    meta: _91_46_46_46id_93LgwFC64VFYMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/card/print/[...id].vue")
  },
  {
    name: "memberships-invoice-id",
    path: "/memberships/invoice/:id()",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/invoice/[id].vue")
  },
  {
    name: "memberships-my-membership",
    path: "/memberships/my-membership",
    meta: indexg8FDLILO2uMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/my-membership/index.vue")
  },
  {
    name: "memberships-update-id",
    path: "/memberships/update/:id()",
    meta: _91id_93mrVImXhxyOMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/update/[id].vue")
  },
  {
    name: "memberships-update-invoice-invoiceId",
    path: "/memberships/update/invoice/:invoiceId()",
    meta: _91invoiceId_936c31ZiOGeEMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/update/invoice/[invoiceId].vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: password_45resetnV1KIbNY4jMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/password-reset.vue")
  },
  {
    name: "payments",
    path: "/payments",
    meta: indexZ7mle6RottMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/payments/index.vue")
  },
  {
    name: "payments-invoice-id",
    path: "/payments/invoice/:id()",
    meta: _91id_93KhZcLIv9JxMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/payments/invoice/[id].vue")
  },
  {
    name: "payments-receipt-id",
    path: "/payments/receipt/:id()",
    meta: _91id_93siwjsx4tauMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/payments/receipt/[id].vue")
  },
  {
    name: "payments-view-id",
    path: "/payments/view/:id()",
    meta: _91id_93Sy75HaHOaYMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/payments/view/[id].vue")
  },
  {
    name: "qb-callback",
    path: "/qb/callback",
    meta: callbackX8ZzUsmRgTMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/qb/callback.vue")
  },
  {
    name: "redirect-logout",
    path: "/redirect/logout",
    meta: logout8TSeNY30xQMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/redirect/logout.vue")
  },
  {
    name: ticketsTiaMJ9cUXYMeta?.name,
    path: "/tickets",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets.vue"),
    children: [
  {
    name: "tickets",
    path: "",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/index.vue")
  },
  {
    name: "tickets-invite-token",
    path: "invite/:token()",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/invite/[token].vue")
  },
  {
    name: "tickets-invite-payment-token",
    path: "invite/payment/:token()",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/invite/payment/[token].vue")
  },
  {
    name: "tickets-payment-code",
    path: "payment/:code()",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/payment/[code].vue")
  },
  {
    name: "tickets-payment",
    path: "payment",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/payment/index.vue")
  },
  {
    name: "tickets-receipt-code",
    path: "receipt/:code()",
    meta: _91code_93cxHxuNi7AlMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/receipt/[code].vue")
  },
  {
    name: "tickets-view-code",
    path: "view/:code()",
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/view/[code].vue")
  }
]
  },
  {
    name: "vote",
    path: "/vote",
    meta: votepjAM2gytRJMeta || {},
    component: () => import("/tmp/codebuild/output/src1803/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/vote.vue")
  },
  {
    name: component_45stubBaRksBa3tIMeta?.name,
    path: "/judge-application",
    component: component_45stubBaRksBa3tI
  },
  {
    name: component_45stubBaRksBa3tIMeta?.name,
    path: "/profile",
    component: component_45stubBaRksBa3tI
  },
  {
    name: component_45stubBaRksBa3tIMeta?.name,
    path: "/login",
    component: component_45stubBaRksBa3tI
  },
  {
    name: component_45stubBaRksBa3tIMeta?.name,
    path: "/join",
    component: component_45stubBaRksBa3tI
  },
  {
    name: component_45stubBaRksBa3tIMeta?.name,
    path: "/memberships/my_membership",
    component: component_45stubBaRksBa3tI
  }
]